import React from 'react';
import styles from '../styles/empresa.module.css';

function EmpleadoForm({ empleadoData, handleEmpleadoChange, handleSaveEmpleado, roles, showModal, setShowModal, editEmpleadoId, equipos, responsables }) {
    return (
        showModal && (
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <h2>{editEmpleadoId ? "Editar Empleado" : "Añadir Empleado"}</h2>

                    <div className={styles.formRow}>
                        <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={empleadoData.nombre}
                            onChange={handleEmpleadoChange}
                        />
                        <input
                            type="text"
                            name="apellido"
                            placeholder="Apellido"
                            value={empleadoData.apellido}
                            onChange={handleEmpleadoChange}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={empleadoData.email}
                            onChange={handleEmpleadoChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Contraseña"
                            value={empleadoData.password}
                            onChange={handleEmpleadoChange}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <div className={styles.inputSelect}>
                            <label htmlFor="id_rol">Rol</label>
                            <select
                                name="id_rol"
                                value={empleadoData.id_rol}
                                onChange={handleEmpleadoChange}
                                className={styles.selectGenero}
                            >
                                <option disabled value="">Seleccione un rol</option>
                                {roles.map((rol) => (
                                    <option key={rol.id_rol} value={rol.id_rol}>
                                        {rol.rol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Pon un selector con los equipos */}
                        <div className={styles.inputSelect}>
                            <label htmlFor="id_equipo">Equipo</label>
                            <select
                                name="id_equipo"
                                value={empleadoData.id_equipo}
                                onChange={handleEmpleadoChange}
                                className={styles.selectGenero}
                            >
                                <option disabled value="">Seleccione un equipo</option>
                                {equipos.map((equipo) => (
                                    <option key={equipo.id_equipo} value={equipo.id_equipo}>
                                        {equipo.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                        <div className={styles.formRow}>
                        <div className={styles.inputSelect}>
                            <label htmlFor="id_responsable">Responsable directo</label>
                            <select
                                name="id_responsable"
                                value={empleadoData.id_responsable}
                                onChange={handleEmpleadoChange}
                                className={styles.selectGenero}
                            >
                                <option disabled value="">Seleccione un responsable</option>
                                {responsables.map((responsable) => (
                                    <option key={responsable.id_empleado} value={responsable.id_empleado}>
                                        {responsable.nombre} {responsable.apellido}
                                    </option>
                                ))}
                            </select>
                            </div>
                        <div className={styles.inputcheckbox}>
                            <input
                                type="checkbox"
                                name="admin_canal_etico"
                                checked={empleadoData.admin_canal_etico}
                                onChange={handleEmpleadoChange}
                                className={styles.checkbox}
                            />
                            <label htmlFor="admin_canal_etico">Admin Canal Ético</label>
                        </div>
                        </div>
                    <div className={styles.formRow}>
                        <button onClick={handleSaveEmpleado}>
                            {editEmpleadoId ? "Guardar Cambios" : "Agregar Empleado"}
                        </button>
                        <button onClick={() => setShowModal(false)} style={{ marginLeft: '10px' }}>
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        )
    );
}

export default EmpleadoForm;