import React, { useState, useRef, useEffect } from 'react';
import useFetch from "../hooks/useFetch";
import styles from '../styles/linkedin.module.css';
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

function LinkedIn() {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [cargo, setCargo] = useState('');
  const [alerta, setAlerta] = useState('');
  const [coincidencia, setCoincidencia] = useState(null);
  const { execute } = useFetch();
  const { data: data2, execute: execute2 } = useFetch();
  const { data: data3, execute: execute3 } = useFetch();
  const { data: data4, execute: execute4 } = useFetch();
  const executeRef = useRef(execute);
  const executeRef2 = useRef(execute2);
  const executeRef3 = useRef(execute3);
  const executeRef4 = useRef(execute4);
  const [listado, setListado] = useState([]);
  const [listadoInicial, setListadoInicial] = useState([]); // Guardar el listado original para no perderlo después de marcar como encontrado
  const [personas, setPersonas] = useState([]);
  const [randomElements, setRandomElements] = useState([]);

  const handleConsultaClick = async (e) => {
    e.preventDefault();
    try {
      const personaExiste = personas.some(persona => persona.nombre === nombre && persona.apellido === apellido && persona.empresa === empresa);
      if (personaExiste) {
        setAlerta('La persona ya está añadida');
        return;
      }

      const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
      if (!nombre || !apellido || !empresa || !cargo) {
        alert("Todos los campos son obligatorios");
        return;
      }
      const response = await executeRef.current(`${apiUrl}/crm/addLinkedin`, "POST", { nombre, apellido, empresa, cargo });
      if (response && response.status === 201) {
        alert("Datos enviados correctamente");
        setNombre("");
        setApellido("");
        setEmpresa("");
        setCargo("");
        setAlerta('');
        setCoincidencia(null); // Limpiar coincidencia después de enviar los datos
      }
    } catch (error) {
      console.error("Error al realizar la consulta:", error);
    }
  };

  const fetchApiData = async (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
    try {
      await executeRef.current(`${apiUrl}${url}`, "GET");
    } catch (error) {
      console.error("Error al realizar la petición:", error);
    }
  };

  const handleMarcarEncontrado = async (id, encontrado) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
    try {
      const response = await executeRef4.current(`${apiUrl}/crm/marcarEncontrado`, "POST", { id_listado: id, encontrado });
      if (response && response.status === 200) {
        // Actualizar el listado localmente
        setListado(prevListado => {
          const index = prevListado.findIndex(item => item.id_listado === id);
          if (index === -1) return prevListado;

          // Obtener una nueva empresa del listado original que no esté en randomElements
          const newEmpresa = listado.find(item => !randomElements.includes(item) && item.id_listado !== id);
          if (!newEmpresa) return prevListado;

          // Mover el elemento hacia arriba
          const updatedListado = [...prevListado];
          const [movedItem] = updatedListado.splice(index, 1);
          updatedListado.push(newEmpresa);

          // Actualizar randomElements sin el elemento encontrado
          setRandomElements(prevRandomElements => {
            const updatedRandomElements = [...prevRandomElements];
            updatedRandomElements.splice(index, 1);
            updatedRandomElements.push(newEmpresa);
            return updatedRandomElements;
          });

          return updatedListado;
        });

        // Actualizar el estado listadoInicial
        setListadoInicial(prevListadoInicial => {
          const updatedListadoInicial = prevListadoInicial.map(item =>
            item.id_listado === id ? { ...item, encontrado } : item
          );
          return updatedListadoInicial;
        });
      }
    } catch (error) {
      console.error("Error al marcar como encontrado:", error);
    }
  };

  useEffect(() => {
    fetchApiData("/crm/listado", executeRef2);
    fetchApiData("/crm/dashboard", executeRef3);
  }, []);

  useEffect(() => {
    if (data2) {
      setListadoInicial(data2.listado);
      const filteredListado = data2.listado.filter(item => item.encontrado === null);
      setListado(filteredListado);

      const getRandomElements = (arr, num) => {
        const shuffled = arr.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
      };
      const randomElements = getRandomElements(filteredListado, 15);
      setRandomElements(randomElements);
    }
    if (data3) {
      setPersonas(data3.personas);
    }
  }, [data2, data3]);

  useEffect(() => {
    if (apellido) {
      const coincidencia = personas.find(persona => persona.nombre.toLowerCase() === nombre.toLowerCase() && persona.apellido.toLowerCase().includes(apellido.toLowerCase()));
      setCoincidencia(coincidencia);
    } else {
      setCoincidencia(null);
    }
  }, [nombre, apellido, personas]);

  const renderColumns = () => {
    return randomElements.map((item, index) => (
      <div className={styles.item} key={item.id_listado}>
        <div className={styles.empresa}>{item.empresa}</div>
        <div className={styles.actions}>
          <button className={`${styles['item-button']} ${styles.success}`} onClick={() => handleMarcarEncontrado(item.id_listado, true)}><FaCheck /></button>
          <button className={`${styles['item-button']} ${styles.danger}`} onClick={() => handleMarcarEncontrado(item.id_listado, false)}><IoMdClose /></button>
        </div>
      </div>
    ));
  };

  // Calcular el porcentaje de empresas procesadas
  const porcentajeEmpresasProcesadas = ((listadoInicial.filter(item => item.encontrado === true).length + listadoInicial.filter(item => item.encontrado === false).length) / listadoInicial.length * 100).toFixed(2);

  return (
    <div className={styles.container}>
      {alerta && <div className={styles.alert}>{alerta}</div>}
      <form onSubmit={handleConsultaClick} className={styles.form}>
        <div className={styles['form-row']}>
          <div className={styles['form-group']}>
            <label htmlFor="nombre" className={styles['form-label']}>Nombre</label>
            <input type="text" className={styles['form-control']} id="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="apellido" className={styles['form-label']}>Apellido</label>
            <input type="text" className={styles['form-control']} id="apellido" value={apellido} onChange={(e) => setApellido(e.target.value)} />
          </div>
        </div>
        <div className={styles['form-row']}>
          <div className={styles['form-group']}>
            <label htmlFor="empresa" className={styles['form-label']}>Empresa</label>
            <input type="text" className={styles['form-control']} id="empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="cargo" className={styles['form-label']}>Cargo</label>
            <select className={styles['form-select']} id="cargo" value={cargo} onChange={(e) => setCargo(e.target.value)}>
              <option value="">Selecciona un cargo</option>
              <option value="CEO">CEO</option>
              <option value="Founder">Founder</option>
              <option value="Co-Founder">Co-Founder</option>
              <option value="RRHH Director">RRHH Director</option>
              <option value="Alto Cargo">Alto Cargo</option>
            </select>
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className={styles.button}>Enviar</button>
        </div>
      </form>
      {coincidencia && (
        <div className={styles.coincidencia}>
          <p>Coincidencia encontrada:</p>
          <p><strong>Nombre:</strong> {coincidencia.nombre}</p>
          <p><strong>Apellido:</strong> {coincidencia.apellido}</p>
          <p><strong>Empresa:</strong> {coincidencia.empresa.nombre_empresa}</p>
        </div>
      )}
      <h2 className={styles.heading}>Listado de empresas</h2>
      <p>({porcentajeEmpresasProcesadas}%)</p>
      <div className={styles.listado}>
        {renderColumns()}
      </div>
    </div>
  );
}

export default LinkedIn;