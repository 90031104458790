import React from "react";
import styles from "../styles/empresa.module.css";

function EmpleadosTable({ empleados, roles, handleEditClick, handleDeleteClick }) {
    return (
        <div className={styles.empleadosSection}>
            <h2>Empleados</h2>
            {empleados && empleados.length > 0 ? (
                <table className={styles.empleadosTable}>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Email</th>
                            <th>Rol</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empleados.map((empleado) => (
                            <tr key={empleado.id_empleado}>
                                <td>{empleado.nombre}</td>
                                <td>{empleado.apellido}</td>
                                <td>{empleado.email}</td>
                                <td>{roles.find((rol) => rol.id_rol === empleado.id_rol)?.rol}</td>
                                <td>
                                    <button onClick={() => handleEditClick(empleado)}>Editar</button>
                                    <button onClick={() => handleDeleteClick(empleado)}>Eliminar</button>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay empleados registrados en esta empresa.</p>
            )}
        </div>
    );
}

export default EmpleadosTable;