import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/gestion.module.css';
import useFetch from "../hooks/useFetch";

function GestionAPP() {
    const { data, execute } = useFetch();
    const { execute: executeEmpresa } = useFetch();
    const executeRef = useRef(execute);
    const executeEmpresaRef = useRef(executeEmpresa);
    const [empresas, setEmpresas] = useState([]);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [selectedEmpresa, setSelectedEmpresa] = useState(null);
    const navigate = useNavigate();
    const [captcha, setCaptcha] = useState({ num1: 0, num2: 0 });
    const [captchaAnswer, setCaptchaAnswer] = useState('');
    const [nuevaEmpresa, setNuevaEmpresa] = useState({
        nombre: '',
        cif: '',
        direccion: '',
        ciudad: '',
        codigo_postal: '',
        provincia: '',
        pais: '',
        empleados: '',
        demo: 0,
        logo: 'https://reviventum-imagesprofile.s3.eu-west-3.amazonaws.com/empresa-63-logo_500x500.jpg'
    });

    const fetchApiData = async (url, executeRef) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "GET");
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    const fetchApiDataPost = async (url, executeRef, body) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "POST", body);
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    useEffect(() => {
        fetchApiData("/backoffice/empresas", executeRef);
    }, []);

    useEffect(() => {
        if (data) {
            setEmpresas(data);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNuevaEmpresa({ ...nuevaEmpresa, [name]: value });
    };

    const handleAddEmpresa = () => {
        setEmpresas([...empresas, nuevaEmpresa]);
        setNuevaEmpresa({
            nombre: '',
            cif: '',
            direccion: '',
            ciudad: '',
            codigo_postal: '',
            provincia: '',
            pais: '',
            empleados: '',
            demo: 0,
            logo: ''
        });
        fetchApiDataPost("/backoffice/crear-empresa", executeEmpresaRef, nuevaEmpresa);
    };

    const handleGestionClick = (empresa) => {
        navigate(`/empresa/${empresa.id_empresa}`);
    };

    // Show confirmation popup
    const handleDeleteClick = (empresa) => {
        setSelectedEmpresa(empresa);
        setShowConfirmPopup(true);
        const num1 = Math.floor(Math.random() * 10);  // Número aleatorio de 0 a 9
        const num2 = Math.floor(Math.random() * 10);  // Número aleatorio de 0 a 9
        setCaptcha({ num1, num2 });
        setCaptchaAnswer(''); // Reiniciar la respuesta del usuario
    };

    // Confirm delete action
    const confirmDelete = async () => {
        const correctAnswer = captcha.num1 + captcha.num2;
        if (parseInt(captchaAnswer) === correctAnswer) {
            // Si la respuesta es correcta, eliminar la empresa
            if (selectedEmpresa) {
                try {
                    await fetchApiDataPost("/backoffice/deleteEmpresa", executeEmpresaRef, { id_empresa: selectedEmpresa.id_empresa });
                    setEmpresas(empresas.filter(empresa => empresa.id_empresa !== selectedEmpresa.id_empresa));
                } catch (error) {
                    console.error("Error al eliminar la empresa:", error);
                } finally {
                    setShowConfirmPopup(false);
                    setSelectedEmpresa(null);
                }
            }
        } else {
            alert("LAS MATEMÁTICAS NO SON LO TUYO");
        }
    };
    

    // Cancel confirmation popup
    const cancelDelete = () => {
        setShowConfirmPopup(false);
        setSelectedEmpresa(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.listSection}>
                <h2>Lista de Empresas</h2>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Nombre</th>
                            <th>CIF</th>
                            <th>Empleados</th>
                            <th>Demo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {empresas.map((empresa, index) => (
                            <tr key={index}>
                                <td><img src={empresa.logo} alt="logo" className={styles.empresaLogo} /></td>
                                <td>{empresa.nombre}</td>
                                <td>{empresa.cif}</td>
                                <td>{empresa.empleados}</td>
                                <td>{empresa.demo ? 'Sí' : 'No'}</td>
                                <td>
                                    <button onClick={() => handleGestionClick(empresa)} className={styles.gestionButton}>Gestionar</button>
                                    <button onClick={() => handleDeleteClick(empresa)} className={styles.deleteButton}>Eliminar</button>                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.formSection}>
                <h2>Crear Nueva Empresa</h2>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="nombre"
                    value={nuevaEmpresa.nombre}
                    onChange={handleChange}
                    placeholder="Nombre de la empresa"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="cif"
                    value={nuevaEmpresa.cif}
                    onChange={handleChange}
                    placeholder="CIF"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="direccion"
                    value={nuevaEmpresa.direccion}
                    onChange={handleChange}
                    placeholder="Dirección"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="ciudad"
                    value={nuevaEmpresa.ciudad}
                    onChange={handleChange}
                    placeholder="Ciudad"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="codigo_postal"
                    value={nuevaEmpresa.codigo_postal}
                    onChange={handleChange}
                    placeholder="Código Postal"
                    className={styles.inputGestion}
                />
                <input
                    type="text"
                    name="provincia"
                    value={nuevaEmpresa.provincia}
                    onChange={handleChange}
                    placeholder="Provincia"
                    className={styles.inputGestion}
                />
                </div>
                <div className={styles.dosinputshorizontal}>
                <input
                    type="text"
                    name="pais"
                    value={nuevaEmpresa.pais}
                    onChange={handleChange}
                    placeholder="País"
                    className={styles.inputGestion}
                />
                <input
                    type="number"
                    name="empleados"
                    value={nuevaEmpresa.empleados}
                    onChange={handleChange}
                    placeholder="Número de empleados"
                    className={styles.inputGestion}
                />
                </div>
                <button onClick={handleAddEmpresa} className={styles.addButton}>Agregar Empresa</button>
            </div>
            {/* Confirmation Popup */}
            {showConfirmPopup && (
                <div className={styles.confirmPopupOverlay}>
                    <div className={styles.confirmPopup}>
                        <p>¿Estás seguro de que deseas eliminar la empresa {selectedEmpresa.nombre}?</p>
                        <p>¿Cuánto es {captcha.num1} + {captcha.num2}?</p>
                        <input
                            type="number"
                            value={captchaAnswer}
                            onChange={(e) => setCaptchaAnswer(e.target.value)}
                            placeholder="Respuesta"
                            className={styles.captchaInput}
                        />
                        <div className={styles.confirmButtons}>
                            <button onClick={confirmDelete} className={styles.confirmButton}>Confirmar</button>
                            <button onClick={cancelDelete} className={styles.cancelButton}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default GestionAPP;
