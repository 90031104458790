import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/login.module.css';
import useFetch from "../hooks/useFetch";

const Login = () => {
  const [correo, setCorreo] = useState('');
  const [password, setPassword] = useState('');
  const { execute, error, data } = useFetch();
  const executeRef = useRef(execute);
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
    try {
      const response = await executeRef.current(`${apiUrl}/auth/login`, "POST", { correo, password });
      if (response && response.status === 200) {
      } else {
        alert("Usuario o contraseña incorrectos");
      }
    } catch (err) {
      alert(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem('token', data.token);
      setCorreo("");
      setPassword("");
      navigate('/'); // Redirigir al usuario a la página de inicio
    }
  }, [data]);

  return (
    <div className={styles.loginBox}>
      <div className={styles.imglogo}>
        <img src="https://feelinback.com/img/logo_feelinback_largo.png" alt="Logo" />
      </div>
      <form action="/auth" method="POST" className={styles.formularioLogin} onSubmit={handleSubmit}>
        <div className={styles.userBox}>
          <input
            type="text"
            name="user"
            required
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
          <label>Correo</label>
        </div>
        <div className={styles.userBox}>
          <input
            type="password"
            name="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Password</label>
        </div>
        <button type="submit" className={styles.btnLogin}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
