import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from "../hooks/useFetch";
import 'bootstrap/dist/css/bootstrap.min.css';

function Persona() {
    const { data, loading, execute, error } = useFetch();
    const executeRef = useRef(execute);
    const [persona, setPersona] = useState([]);
    const paramIDPersona = window.location.pathname.split("/")[3];
    const navigate = useNavigate();  // Hook para la navegación

    const fetchApiData = async (url, method = "GET", body = null) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            const response = await executeRef.current(`${apiUrl}${url}`, method, body);
            if (response.ok) {  // Asegúrate de verificar alguna condición de éxito
                if (method === "DELETE") {
                    navigate('/crm');  // Navega a /crm si la eliminación fue exitosa
                } else if (method === "PUT") {
                    window.location.reload();  // Recarga la página si la actualización fue exitosa
                }
            }
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    useEffect(() => {
        fetchApiData("/crm/person/" + paramIDPersona);
    }, [paramIDPersona]);

    useEffect(() => {
        if (data && data.persona) {
            setPersona(data.persona);
        }
    }, [data]);

    const handleDelete = () => {
        fetchApiData(`/crm/person/${paramIDPersona}`, "DELETE");
    };

    const handleUpdate = () => {
        fetchApiData(`/crm/person/${paramIDPersona}`, "PUT");
    };

    if (loading) return <div className="container mt-5"><p>Loading...</p></div>;
    if (error) return <div className="container mt-5"><p>Error: {error.message}</p></div>;
    if (!persona) return <div className="container mt-5"><p>No data found.</p></div>;


    return (
        <div className="container mt-5">
            <div className="card">
                <div className="card-header">
                    <h2>{persona.nombre} {persona.apellido}</h2>
                </div>
                <div className="card-body">
                    <p className="card-text"><strong>Cargo:</strong> {persona.cargo}</p>
                    <p className="card-text"><strong>Empresa:</strong> {persona.nombre_empresa}</p>
                    {persona.correos && (
                        <div>
                            <p><strong>Correos:</strong></p>
                            <ul>
                                {persona.correos.map((correo, index) => (
                                    <li key={index}>{correo.correo}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {persona.enviado_mensaje && (
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                            <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                            </svg>
                        </div>
                    )}
                    <button className="btn btn-danger" onClick={handleDelete}>Eliminar</button>
                    <button className="btn btn-info" onClick={handleUpdate}>Marcar como contactado por LinkedIn</button>
                
                </div>
            </div>
        </div>
    );
}

export default Persona;
