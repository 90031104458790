import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Inicio from '../pages/inicio';
import Correos from '../pages/emails';
import CRM from '../pages/Crm';
import LinkedIn from '../pages/LinkedIn';
import Sidebar from '../components/Sidebar';
import Persona from '../pages/Persona';
import Login from '../pages/Login';
import PrivateRoute from '../components/PrivateRoute';
import styles from '../styles/mainComponents.module.css';
import Server from '../pages/Server';
import Gestion from '../pages/GestionAPP';
import Empresa from '../pages/Empresa';
import EmailMarketing from '../pages/EmailMarketing';

const RoutesComponent = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div className={styles.appContainer}>
      {!isLoginPage && <Sidebar />}
      <div className={styles.content}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Inicio />
              </PrivateRoute>
            }
          />
          <Route
            path="/emails"
            element={
              <PrivateRoute>
                <Correos />
              </PrivateRoute>
            }
          />
          <Route
            path="/crm"
            element={
              <PrivateRoute>
                <CRM />
              </PrivateRoute>
            }
          />
          <Route
            path="/linkedin"
            element={
              <PrivateRoute>
                <LinkedIn />
              </PrivateRoute>
            }
          />
          <Route
            path="/server"
            element={
              <PrivateRoute>
                <Server />
              </PrivateRoute>
            }
          />
          <Route
            path="/app"
            element={
              <PrivateRoute>
                <Gestion />
              </PrivateRoute>
            }
          />
          <Route
            path="/crm/person/:id"
            element={
              <PrivateRoute>
                <Persona />
              </PrivateRoute>
            }
          />
          <Route
            path="/empresa/:id"
            element={
              <PrivateRoute>
                <Empresa />
              </PrivateRoute>
            }
          />
          <Route
            path='/emailMarketing'
            element={
              <PrivateRoute>
                <EmailMarketing />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <RoutesComponent />
  </Router>
);

export default App;
