import React, { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from "../hooks/useFetch";
import { FaEnvelope, FaClock } from 'react-icons/fa'; 
import style from '../styles/correos.module.css';

function Correos() {
  const navigate = useNavigate();
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [dominio, setDominio] = useState('');
  const [cargo, setCargo] = useState('');
  const [empleado, setEmpleado] = useState('');
  const { loading, execute } = useFetch();
  const { data: data2, loading: loading2, execute: execute2 } = useFetch();
  const executeRef = useRef(execute);
  const executeRef2 = useRef(execute2);
  const [emailsPendientes, setEmailsPendientes] = useState([]);

  const handleNavigate = () => {
    navigate('/');
  };

  useEffect(() => {
    if (data2) {
      setEmailsPendientes(data2.correosPendientes || []); // Asegúrate de que correosPendientes exista en la respuesta y si no, asigna un arreglo vacío
    }
  }, [data2]);
  

  const handleConsultaClick = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
      const response = await executeRef.current(`${apiUrl}/email/verify-email`, "POST", {nombre: nombre, apellido: apellido, empresa: empresa, dominio: dominio, cargo: cargo, empleado: empleado});
      if (response && response.status === 201) {
        alert("Datos enviados correctamente");
        setNombre("");
        setApellido("");
        setEmpresa("");
        setDominio("");
        setCargo("");
        setEmpleado("");
        fetchApiData("/email/dashboard", executeRef2);
      }
    } catch (error) {
      console.error("Error al realizar la consulta:", error);
    }
  };

  useEffect(() => {
    executeRef2.current = execute2;
  }, [execute2]);

  const fetchApiData = async (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
    try {
      await executeRef.current(`${apiUrl}${url}`, "GET");
    } catch (error) {
      console.error("Error al realizar la petición:", error);
    }
  };

  useEffect(() => {
    fetchApiData("/email/dashboard", executeRef2);
  }
  , []);

  return (
<div className={style.container}>
      <button onClick={handleNavigate} className="btn btn-secondary mb-3">Volver a Home</button>
      <form onSubmit={handleConsultaClick} className="row g-3">
        <div className="col-md-6">
          <label htmlFor="nombre" className="form-label">Nombre</label>
          <input type="text" className="form-control" id="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label htmlFor="apellido" className="form-label">Apellido</label>
          <input type="text" className="form-control" id="apellido" value={apellido} onChange={(e) => setApellido(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label htmlFor="empresa" className="form-label">Empresa</label>
          <input type="text" className="form-control" id="empresa" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label htmlFor="dominio" className="form-label">Dominio</label>
          <input type="text" className="form-control" id="dominio" value={dominio} onChange={(e) => setDominio(e.target.value)} />
        </div>
        <div className="col-md-6">
          <label htmlFor="cargo" className="form-label">Cargo</label>
          <select className="form-select" id="cargo" value={cargo} onChange={(e) => setCargo(e.target.value)}>
            <option value="">Selecciona un cargo</option>
            <option value="CEO">CEO</option>
            <option value="Founder">Founder</option>
            <option value="Co-Founder">Co-Founder</option>
            <option value="RRHH Director">RRHH Director</option>
            <option value="Alto Cargo">Alto Cargo</option>
          </select>
        </div>
        <div className="col-md-6">
          <label htmlFor="empleado" className="form-label">Empleado</label>
          <input type="text" className="form-control" id="empleado" value={empleado} onChange={(e) => setEmpleado(e.target.value)} />
        </div>
        <div className="col-12">
          <button onClick={handleConsultaClick} className="btn btn-primary">Enviar</button>
        </div>
      </form>
      {loading && <div className="mt-3">Cargando...</div>}
  <div className="row justify-content-start mt-3">
    <EmailsList emails={emailsPendientes} title="Emails Pendientes" loading={loading2} />
  </div>
</div>
  );
}

export default Correos;
const EmailsList = ({ emails, title, loading }) => {
  if (loading) {
    return (
      <div className="col">
        <div className="card border-0 shadow bg-body rounded">
          <div className="card-body text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="col">
      <div className="card border-0 shadow bg-body rounded">
        <div className="card-body text-center">
          <h5 className="card-title text-primary">
            <FaEnvelope className="me-2" />{title}
          </h5>
          {emails && emails.length > 0 ? (
            <ul className="list-group list-group-flush text-start">
              {emails.map((email, index) => (
                <li key={index} className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <span className="fw-bold">{email.correo.correo}</span>
                    <div>
                      <FaClock className="text-warning me-2" />
                      <span className="text-muted">
                        Programado para: {new Date(email.hora_programada).toLocaleDateString('es-ES', {
                          year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'
                        })}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="card-text">No hay correos {title.toLowerCase()}.</p>
          )}
        </div>
      </div>
    </div>
  );
};
