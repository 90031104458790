import React, { useRef } from 'react';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import styles from '../styles/organigrama.module.css';
import html2canvas from 'html2canvas';

// Función para convertir los datos en un formato de árbol
const buildTree = (employees) => {
  const map = {};
  employees.forEach((employee) => {
    map[employee.email] = { ...employee, children: [] };
  });

  const tree = [];
  employees.forEach((employee) => {
    if (employee.responsable) {
      map[employee.responsable].children.push(map[employee.email]);
    } else {
      tree.push(map[employee.email]);
    }
  });

  return tree[0]; // Devolvemos el nodo raíz
};

const MyNode = ({ node }) => {
    return (
      <div className={styles.modernNode}>
        <p className={styles.name}>{node.name}</p>
        <p className={styles.departamento}>{node.departamento}</p>
      </div>
    );
  };

// Componente principal
function Server() {
    const orgChartRef = useRef();

    const employees = [
        {
          "name": "Manuela Becerra Fuentes",
          "email": "manuela@example.com",
          "departamento": "Commercial",
          "responsable": null
        },
        {
          "name": "Nuno Matias",
          "email": "nuno@example.com",
          "departamento": "Commercial",
          "responsable": null
        },
        {
          "name": "Ruben Pandolfi",
          "email": "ruben@example.com",
          "departamento": "Commercial",
          "responsable": null
        },
        {
          "name": "Silvia Mazzanti",
          "email": "silvia@example.com",
          "departamento": "Commercial",
          "responsable": null
        },
        {
          "name": "Ambar Amelia Ceballos",
          "email": "ambar@example.com",
          "departamento": "Operations",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Ana Carina Peña Espinosa",
          "email": "ana.carina@example.com",
          "departamento": "Sales",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Leticia Rodriguez Menzer",
          "email": "leticia@example.com",
          "departamento": "Marketing",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Lucas Guardino",
          "email": "lucas@example.com",
          "departamento": "Development",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Monica Sorribas",
          "email": "monica@example.com",
          "departamento": "Sales",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Rebeca Saavedra Diaz",
          "email": "rebeca@example.com",
          "departamento": "Customer Care",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Victoria Diaz Riola",
          "email": "victoria@example.com",
          "departamento": "General Management",
          "responsable": "manuela@example.com"
        },
        {
          "name": "Eric Lopez Maestro",
          "email": "eric@example.com",
          "departamento": "Technical Support",
          "responsable": "rebeca@example.com"
        },
        {
          "name": "Maria Katalina Zubieta",
          "email": "maria.katalina@example.com",
          "departamento": "Phone Support",
          "responsable": "rebeca@example.com"
        },
        {
          "name": "Sandra Serra Sena",
          "email": "sandra@example.com",
          "departamento": "Customer Service",
          "responsable": "rebeca@example.com"
        },
        {
          "name": "Enrique Carrillo Vega",
          "email": "enrique@example.com",
          "departamento": "Technical Support",
          "responsable": "eric@example.com"
        },
        {
          "name": "Jana Illa",
          "email": "jana@example.com",
          "departamento": "Technical Support",
          "responsable": "eric@example.com"
        },
        {
          "name": "Marc Bautista",
          "email": "marc@example.com",
          "departamento": "Technical Support",
          "responsable": "eric@example.com"
        },
        {
          "name": "Maria Hernandez Jimenez",
          "email": "maria@example.com",
          "departamento": "Technical Support",
          "responsable": "eric@example.com"
        },
        {
          "name": "Marta Colás Chacón",
          "email": "marta@example.com",
          "departamento": "Technical Support",
          "responsable": "eric@example.com"
        }
      ];

  const tree = buildTree(employees);

  const handleExport = () => {
    html2canvas(orgChartRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'organigrama.png';
      link.click();
    });
  };

  return (
    <div>
      <h1>Organigrama de la Empresa</h1>
      <div ref={orgChartRef}>
        <OrgChart tree={tree} NodeComponent={MyNode} />
      </div>
      <button onClick={handleExport} className="exportButton">Exportar como Imagen</button>
    </div>
  );
}

export default Server;