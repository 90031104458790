import React, { useState, useRef, useEffect } from 'react';
import useFetch from "../hooks/useFetch";
import Loader from '../components/Loader';
import styles from '../styles/linkedin.module.css';

function EmailMarketing() {
    const { data, loading, execute } = useFetch();
    const executeRef = useRef(execute);
    const [personas, setPersonas] = useState([]);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState(null);

    useEffect(() => {
        executeRef.current = execute;
    }, [execute]);

    const fetchApiData = async (url, executeRef) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "GET");
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    const fetchApiDataPost = async (url, executeRef, body) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "POST", body);
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    useEffect(() => {
        if (Array.isArray(data)) {
            setPersonas(data);
        }
    }, [data]);

    useEffect(() => {
        fetchApiData("/crm/getPersonas", executeRef);
    }, []);

    // Function to format date and time
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    // Show confirmation popup
    const handleBajaClick = (persona) => {
        setSelectedPersona(persona);
        setShowConfirmPopup(true);
    };

    // Confirm baja action
    const confirmBaja = async () => {
        if (selectedPersona) {
            try {
                // Realizar la petición de eliminación
                await fetchApiDataPost("/crm/deletePersona", executeRef, { id_datosPruebaIa: selectedPersona.id_datosPruebaIa });
                // Actualizar la lista de personas después de la eliminación
                setPersonas(prevPersonas => prevPersonas.filter(persona => persona.id_datosPruebaIa !== selectedPersona.id_datosPruebaIa));
                fetchApiData("/crm/getPersonas", executeRef);
            } catch (error) {
                console.error("Error al dar de baja a la persona:", error);
            } finally {
                setShowConfirmPopup(false);
                setSelectedPersona(null);
            }
        }
    };

    // Cancel confirmation popup
    const cancelBaja = () => {
        setShowConfirmPopup(false);
        setSelectedPersona(null);
    };

    return (
        <div className={styles.container}>
            {loading && <Loader />}
            <h2 className={styles.emailMarketingTitle}>Listado</h2>
            <table className={styles.emailMarketingTable}>
                <thead>
                    <tr className={styles.emailMarketingTableHeaderRow}>
                        <th className={styles.emailMarketingTableHeader}>Nombre</th>
                        <th className={styles.emailMarketingTableHeader}>Apellido</th>
                        <th className={styles.emailMarketingTableHeader}>Email</th>
                        <th className={styles.emailMarketingTableHeader}>Respuesta</th>
                        <th className={styles.emailMarketingTableHeader}>Fecha inscripción</th>
                        <th className={styles.emailMarketingTableHeader}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(personas) && personas.map(persona => (
                        <tr key={persona.id_datosPruebaIa} className={styles.emailMarketingTableRow}>
                            <td className={styles.emailMarketingTableData}>{persona.nombre}</td>
                            <td className={styles.emailMarketingTableData}>{persona.apellido}</td>
                            <td className={styles.emailMarketingTableData}>{persona.email}</td>
                            <td className={styles.emailMarketingTableData}>{persona.respuesta}</td>
                            <td className={styles.emailMarketingTableData}>{formatDateTime(persona.fecha_creacion)}</td>
                            <td className={styles.emailMarketingTableData}>
                                <button 
                                    className={styles.emailMarketingButton} 
                                    onClick={() => handleBajaClick(persona)}>
                                    Dar de baja
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Confirmation Popup */}
            {showConfirmPopup && (
                <div className={styles.confirmPopupOverlay}>
                    <div className={styles.confirmPopup}>
                        <p>¿Estás seguro de que deseas dar de baja a {selectedPersona.nombre} {selectedPersona.apellido}?</p>
                        <button className={styles.confirmButton} onClick={confirmBaja}>Confirmar</button>
                        <button className={styles.cancelButton} onClick={cancelBaja}>Cancelar</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EmailMarketing;
