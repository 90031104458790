import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaLinkedin, FaTools, FaServer, FaReact, FaChild } from 'react-icons/fa'; // Importamos iconos de FontAwesome
import styles from '../styles/sidebar.module.css';

function Sidebar() {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <img src="https://feelinback.com/img/logo_feelinback.png" alt="logo" />
        <p>FeelinBack</p>
      </div>
      <ul className={styles.nav}>
        <li className={styles['nav-item']}>
          <Link to="/" className={`${styles['nav-link']} ${isActive('/') ? styles.active : ''}`} aria-current="page">
            <FaHome className={styles.icon} /> Inicio
          </Link>
        </li>
        {/* <li className={styles['nav-item']}>
          <Link to="/emails" className={`${styles['nav-link']} ${isActive('/emails') ? styles.active : ''}`}>
            <FaEnvelope className={styles.icon} /> Correos
          </Link>
        </li> */}
        <li className={styles['nav-item']}>
          <Link to="/linkedin" className={`${styles['nav-link']} ${isActive('/linkedin') ? styles.active : ''}`}>
            <FaLinkedin className={styles.icon} /> LinkedIn
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link to="/crm" className={`${styles['nav-link']} ${isActive('/crm') ? styles.active : ''}`}>
            <FaTools className={styles.icon} /> CRM
          </Link>
        </li>
        {/* App */}
        <li className={styles['nav-item']}>
          <Link to="/app" className={`${styles['nav-link']} ${isActive('/app') ? styles.active : ''}`}>
            <FaReact className={styles.icon} /> APP
          </Link>
        </li>
        {/* Gestion de Email Marketing */}
        <li className={styles['nav-item']}>
          <Link to="/emailMarketing" className={`${styles['nav-link']} ${isActive('/emailMarketing') ? styles.active : ''}`}>
            <FaChild className={styles.icon} /> Email Marketing
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
