import React from "react";
import Routes from "./routes/routes";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <Routes  />
    </div>
  );
}

export default App;
