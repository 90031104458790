import React from 'react';
import styles from '../styles/empresa.module.css';

function EmpresaDetails({ empresa, formData, handleChange, editMode, handleSave, setEditMode }) {
    return (
        <div className={styles.card}>
                {editMode ? (
                    <div>
                        <div className={styles.formGroup}>
                            <label htmlFor="nombre">Nombre de la empresa</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="cif">CIF</label>
                            <input
                                type="text"
                                name="cif"
                                value={formData.cif || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="direccion">Dirección</label>
                            <input
                                type="text"
                                name="direccion"
                                value={formData.direccion || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="ciudad">Ciudad</label>
                            <input
                                type="text"
                                name="ciudad"
                                value={formData.ciudad || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="codigo_postal">Código Postal</label>
                            <input
                                type="text"
                                name="codigo_postal"
                                value={formData.codigo_postal || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="provincia">Provincia</label>
                            <input
                                type="text"
                                name="provincia"
                                value={formData.provincia || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="pais">País</label>
                            <input
                                type="text"
                                name="pais"
                                value={formData.pais || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="empleados">Número de empleados</label>
                            <input
                                type="number"
                                name="empleados"
                                value={formData.empleados || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <button onClick={handleSave}>Guardar</button>
                        <button onClick={() => setEditMode(false)} style={{ marginLeft: '10px' }}>
                            Cancelar
                        </button>
                    </div>
                ) : (
                    <div>
                        <p><strong>Nombre:</strong> {empresa?.nombre}</p>
                        <p><strong>CIF:</strong> {empresa?.cif}</p>
                        <p><strong>Dirección:</strong> {empresa?.direccion}</p>
                        <p><strong>Ciudad:</strong> {empresa?.ciudad}</p>
                        <p><strong>Código Postal:</strong> {empresa?.codigo_postal}</p>
                        <p><strong>Provincia:</strong> {empresa?.provincia}</p>
                        <p><strong>País:</strong> {empresa?.pais}</p>
                        <p><strong>Empleados:</strong> {empresa?.empleados}</p>
                        <button onClick={() => setEditMode(true)}>Editar</button>
                        {/* <button onClick={() => setShowModal(true)} style={{ marginLeft: '10px' }}>Añadir Empleado</button> */}
                    </div>
                )}
            </div>
    );
}

export default EmpresaDetails;