import React, { useState, useRef, useEffect, useMemo } from 'react';
import useFetch from "../hooks/useFetch";
import { useNavigate } from 'react-router-dom';
import styles from '../styles/linkedin.module.css';

function Crm() {
    const { data, loading, execute, error } = useFetch();
    const [personas, setPersonas] = useState([]);
    const executeRef = useRef(execute);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const navigate = useNavigate();
    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); 
    };

    const filteredPersonas = useMemo(() => personas.filter(persona =>
        persona.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.apellido.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.cargo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        persona.nombre_empresa.toLowerCase().includes(searchTerm.toLowerCase())
    ), [personas, searchTerm]);

    const totalPages = Math.ceil(filteredPersonas.length / itemsPerPage);

    useEffect(() => {
        executeRef.current = execute;
    }, [execute]);

    const fetchApiData = async (url, executeRef) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeRef.current(`${apiUrl}${url}`, "GET");
        } catch (error) {
            console.error("Error al realizar la petición:", error);
        }
    };

    useEffect(() => {
        fetchApiData("/crm/dashboard", executeRef);
    }, []);

    useEffect(() => {
        if (data) {
            setPersonas(data.personas);
        }
    }, [data]);

    const goToProfile = (personaId) => {
        navigate(`/crm/person/${personaId}`);  // Redirect to profile page using navigate
    };

    return (
        <div className={styles.container}>
            <input
                type="text"
                className="form-control mb-4"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {loading && <p className='text-center'>Cargando...</p>}
            {error && <p className='text-danger'>Error al cargar los datos: {error}</p>}
            {!loading && filteredPersonas.length === 0 && <p className='text-center'>No hay datos disponibles.</p>}
            <div className='table-responsive'>
                <table className='table table-striped table-hover'>
                    <thead className='table-dark'>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Cargo</th>
                            <th>Empresa</th>
                            <th>Mensaje LinkedIn</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPersonas.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((persona) => (
                            <tr key={persona.id_persona}>
                                <td>{persona.nombre}</td>
                                <td>{persona.apellido}</td>
                                <td>{persona.cargo}</td>
                                <td>{persona.nombre_empresa}</td>
                                <td className="text-center align-middle">
                                    {persona.enviado_mensaje ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                                            <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
                                            <path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                                        </svg>
                                    ) : ''}
                                </td>                                
                                <td>
                                    <button onClick={(e) => {e.stopPropagation(); goToProfile(persona.id_persona);}} className="btn btn-primary btn-sm">
                                        Gestionar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation example">
                <ul className='pagination pagination-sm justify-content-center'>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Anterior</button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                            <button className='page-link' onClick={() => setCurrentPage(i + 1)}>
                                {i + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className='page-link' onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Siguiente</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}       
export default Crm;