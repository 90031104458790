import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importamos useNavigate en lugar de useHistory

function useFetch() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook de React Router v6 para manejar la navegación

  const execute = async (
    url,
    method = "GET",
    body = null,
    headers = {},
    isFormData = false,
  ) => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const options = {
        method,
        headers: isFormData
          ? headers
          : { "Content-Type": "application/json", ...headers },
        body: isFormData ? body : body ? JSON.stringify(body) : null,
        credentials: "include",
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.log("Error Response: ", errorData, response.status); // Más información sobre el error
        if (response.status === 401) {
          navigate("/login"); // Redirige al usuario a /login
          return; // Detenemos la ejecución si redirigimos
        }
        
        throw new Error(errorData.message || "Error en el servidor");
      }
      

      const responseData = await response.json().catch((e) => {
        console.log("Error al parsear la respuesta del servidor", e);
        throw new Error("Respuesta inválida del servidor");
      });
      setData(responseData);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err.message);
      setLoading(false);
      throw err;
    }
  };

  return { loading, data, error, execute };
}

export default useFetch;